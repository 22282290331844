.collection-brand {
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (min-width: 1000px) {
  .collection {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
  }
}

.collection-masthead {
  position: relative;

  .collection-masthead__footnote {
    max-width: 1400px;
    margin: 10px auto 0 10px;
    padding: 0;
    font-size: 11px;

    @media only screen and (min-width: 1000px) {
      margin-left: 0;
    }
  }
}

.collection-masthead__text {
  position: relative;
  top: 50%;
  right: 0;
  left: 0;
  margin: 140px auto 0;
  padding: 0 15px;
  color: #fff;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
}

.collection--sophistone {
  .collection-masthead__text {
    position: static;
    padding: 15px 15px 0;
    background-color: #e0dfe7;
    text-shadow: none;
    transform: none;
  }
}

@media only screen and (min-width: 720px) {
  .collection--sophistone {
    .collection-masthead__text {
      position: absolute;
      top: auto;
      bottom: 15px;
      padding: 0;
      background-color: transparent;
    }
  }
}

.collection__title {
    .collection-masthead__text & {
        margin: 0 0 5px;
        padding: 0;
        font-family: $font-titles;
        font-size: 24px;
        font-weight: normal;
    }
}

.collection-masthead {
  .collection-creator__header {
    display: inline-block;
    margin: 0 auto;
    padding-top: 6px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    border-top: 1px solid;

    span {
      font-weight: normal;
    }

    .collection--sophistone & {
      color: #000;
    }
  }
}

.collection-masthead__logo {
  margin: 0 auto;
}

.collection__feature-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

@media only screen and (min-width: 560px) {
  .collection-masthead__text {
    text-shadow: none;
  }

  .collection__title {
    .collection-masthead__text & {
      margin-bottom: 0;
      font-size: 56px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .collection-masthead__text {
    top: 85%;
    font-size: 37px;
  }

  .collection__title {
    .collection-masthead__text & {
      margin-bottom: 0;
      font-size: 44px;
    }
  }
}

.collection-summary {
  padding: 30px 15px;
  font-family: "Garamond", serif;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;

  .collection-summary__inner p {
    margin: 0;
    padding: 0;
  }

  .collection-summary__title {
    font-size: 2em;
    letter-spacing: 8px;
  }

  .collection-summary__title--alt {
    font-size: 1.25em;
    letter-spacing: 1px;
  }

  .collection-summary__subtitle {
    display: block;
    margin-top: 15px;
    font-size: 0.4em;
    text-transform: none;
    letter-spacing: 0;
  }
}

.collection-summary__inner {
  max-width: 750px;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (min-width: 640px) {
  .collection-summary {
    padding: 60px 30px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 1000px) {
  .collection-summary {
    padding: 60px;
    line-height: 1.75;
  }

  .collection-summary__inner {
    padding: 0 30px;
  }
}

.collection-section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;

  &--info {
    display: block;
    max-width: none;
  }
}

.collection-video {
  padding: 30px 0;
}

.collection-section__full {
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
}

.collection-section__left {
  padding: 15px;
}

.collection-section__right {
  padding: 15px;
}

.collection-section__title {
    font-family: $font-titles;

    .collection-section & {
        margin: 0 0 10px;
        padding: 0;
        font-size: 24px;
        font-weight: normal;
    }
}

.collection-section__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.collection-section__note {
  .collection-section & {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 540px) {
  .collection-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .collection-section__left,
  .collection-section__right {
    padding: 0 10px;
    flex: 1 1 50%;
  }
}

@media only screen and (min-width: 780px) {
  .collection-section {
    font-size: 14px;
    line-height: 1.75;
  }

  .collection-section__title {
    .collection-section & {
      margin-bottom: 30px;
      font-size: 44px;
    }
  }
}

.collection-button {
  padding: 20px 30px;
}

.collection-footer {
  padding: 60px 0;
  text-align: center;
}

.collection-footer__title {
    .collection-footer & {
        margin: 0 0 5px;
        padding: 0;
        font-family: $font-titles;
        font-size: 24px;
    }
}

.collection-footer__link {
    display: block;
    padding: 0 5px;
    color: #fff;
    font-family: $font-titles;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
}

.collection-footer__link-name {
  margin: 15px 0 0 0;
}

.collection-footer__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.collection-footer {
  .bx-pager {
    display: none;
  }

  .bx-wrapper {
    max-width: 700px;
  }

  // .slidewrap {
  // 	padding: 0 5px;
  // }

  .bx-wrapper .bx-controls-direction a {
    top: 40%;
    width: 46px;
    height: 46px;
    margin-top: -23px;
    background-image: url("/content/themes/milbon/images/about-slider-arrows.png");
    background-repeat: no-repeat;
    background-position: 0 0;

    &.bx-prev {
      left: -70px;
    }

    &.bx-next {
      right: -70px;
      background-position: -46px 0;
    }
  }
}

@media only screen and (min-width: 720px) {
  .collection-footer__title {
    .collection-footer & {
      margin-bottom: 30px;
      font-size: 38px;
    }
  }
}

$feature-antifrizz-dark: rgba(240, 123, 37, 1);
$feature-antifrizz-light: rgba(253, 242, 233, 1);

$feature-moisture-dark: rgba(0, 113, 48, 1);
$feature-moisture-light: rgba(230, 241, 235, 1);

$feature-repair-dark: rgba(164, 0, 74, 1);
$feature-repair-light: rgba(246, 229, 237, 1);

$feature-scalp-dark: rgba(85, 175, 173, 1);
$feature-scalp-light: rgba(239, 247, 249, 1);

$feature-smooth-dark: rgba(0, 79, 157, 1);
$feature-smooth-light: rgba(230, 237, 247, 1);

$feature-volume-dark: rgba(162, 95, 163, 1);
$feature-volume-light: rgba(246, 239, 247, 1);

.collection--antifrizz {
  .collection-masthead__text {
    color: $feature-antifrizz-dark;
  }

  .collection-summary {
    background-color: $feature-antifrizz-light;
  }

  .collection-section--info {
    background-color: $feature-antifrizz-light;
  }

  .collection-button {
    background-color: $feature-antifrizz-dark;

    &:hover {
      background-color: darken($feature-antifrizz-dark, 10%);
    }
  }
}

.collection--moisture {
  .collection-masthead__text {
    color: $feature-moisture-dark;
  }

  .collection-summary {
    background-color: $feature-moisture-light;
  }

  .collection-section--info {
    background-color: $feature-moisture-light;
  }

  .collection-button {
    background-color: $feature-moisture-dark;

    &:hover {
      background-color: darken($feature-moisture-dark, 10%);
    }
  }
}

.collection--repair {
  .collection-masthead__text {
    color: $feature-repair-dark;
  }

  .collection-summary {
    background-color: $feature-repair-light;
  }

  .collection-section--info {
    background-color: $feature-repair-light;
  }

  .collection-button {
    background-color: $feature-repair-dark;

    &:hover {
      background-color: darken($feature-repair-dark, 10%);
    }
  }
}

.collection--scalp {
  .collection-masthead__text {
    color: $feature-scalp-dark;
  }

  .collection-summary {
    background-color: $feature-scalp-light;
  }

  .collection-section--info {
    background-color: $feature-scalp-light;
  }

  .collection-button {
    background-color: $feature-scalp-dark;

    &:hover {
      background-color: darken($feature-scalp-dark, 10%);
    }
  }
}

.collection--smooth {
  .collection-masthead__text {
    color: $feature-smooth-dark;
  }

  .collection-summary {
    background-color: $feature-smooth-light;
  }

  .collection-section--info {
    background-color: $feature-smooth-light;
  }

  .collection-button {
    background-color: $feature-smooth-dark;

    &:hover {
      background-color: darken($feature-smooth-dark, 10%);
    }
  }
}

.collection--volume {
  .collection-masthead__text {
    color: $feature-volume-dark;
  }

  .collection-summary {
    background-color: $feature-volume-light;
  }

  .collection-section--info {
    background-color: $feature-volume-light;
  }

  .collection-button {
    background-color: $feature-volume-dark;

    &:hover {
      background-color: darken($feature-volume-dark, 10%);
    }
  }
}

.collection--5-week {
  .collection-masthead {
    max-width: 1400px;
    margin: 0 auto;
  }

  .collection-masthead__text {
    top: auto;
    right: 0;
    bottom: 30px;
    left: auto;
    width: 30.6%;
    max-width: none;
    margin: 0;
    padding: 0 30px;
    text-align: left;
    transform: none;
  }

  .collection__title {
    margin-bottom: 0;
    color: #fff;
    font-family: "Garamond", serif;
    font-size: 24px;
    text-transform: none;

    @media only screen and (min-width: 1150px) {
      span {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .collection-masthead__text {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--5-week {
    .collection__title {
      font-size: 36px;
    }
  }
}

@media only screen and (min-width: 720px) {
  .collection--5-week {
    .collection__title {
      font-size: 52px;
    }
  }
}

.collection--360-degree {
  .collection-masthead {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
  }

  .collection-masthead__text {
    position: absolute;
    top: auto;
    bottom: 0;
    width: 56.55%;
    max-width: none;
    margin-right: 0;
    padding: 0 30px;
    color: #000;
    text-align: left;
    text-shadow: none;
    transform: none;
  }

  .collection__title {
    margin-bottom: 5px;
    font-size: 24px;
    text-transform: none;
  }

  .collection-summary__title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--360-degree {
    .collection__title {
      margin-bottom: 10px;
      font-size: 36px;

      span {
        display: block;
      }
    }

    .collection-summary__title {
      font-size: 24px;
    }
  }
}

@media only screen and (min-width: 560px) {
  .collection--360-degree {
    .collection-masthead__text {
      font-size: 16px;
    }

    .collection__title {
      margin-bottom: 15px;
      font-size: 32px;
    }

    .collection-summary__title {
      font-size: 52px;
    }
  }
}

@media only screen and (min-width: 840px) {
  .collection--360-degree {
    .collection__title {
      font-size: 52px;
    }
  }
}

.collection--about {
  .collection-masthead__text {
    position: static;
    padding: 15px;
    font-size: 14px;
    text-shadow: none;
    transform: none;
  }

  .collection__title {
    margin-bottom: 5px;
    font-family: "Garamond", serif;
    font-size: 24px;
    text-transform: none;
  }

  .collection-summary {
    font-size: 14px;
  }

  .collection-summary__title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--about {
    .collection-masthead__text {
      position: absolute;
      width: 54%;
      max-width: none;
      margin-right: 0;
      padding: 0 30px;
      color: #fff;
      transform: translateY(-50%);
    }

    .collection__title {
      margin-bottom: 10px;
      font-size: 36px;
    }

    .collection-summar {
      font-size: 18px;
    }

    .collection-summary__title {
      font-size: 24px;
    }
  }
}

@media only screen and (min-width: 560px) {
  .collection--about {
    .collection-masthead__text {
      padding: 0 60px;
      font-size: 14px;
    }

    .collection__title {
      margin-bottom: 15px;
      font-size: 52px;
    }

    .collection-summary {
      font-size: 24px;
    }

    .collection-summary__title {
      font-size: 52px;
    }
  }
}

@media only screen and (min-width: 560px) {
  .collection--about {
    .collection-masthead__text {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 820px) {
  .collection--about {
    .collection-masthead__text {
      transform: none;
      font-size: 14px;
    }
  }
}

.collection-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1040px;
  margin: 60px auto;
}

.collection-grid--wide {
  max-width: 1400px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.collection-grid {
  .collection-quote {
    order: 3;
    width: 100%;
  }

  .collection-detail:nth-child(n + 3) {
    order: 4;
  }
}

@media only screen and (min-width: 720px) {
  .collection-grid {
    .collection-quote,
    .collection-detail {
      order: unset !important;
    }
  }
}

.collection-detail {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 0 10px;

  .collection-grid--thirds & {
    max-width: 350px;
  }
}

@media only screen and (min-width: 720px) {
  .collection-detail {
    width: 50%;
    max-width: none;
    margin-bottom: 40px;
    padding: 0 20px;

    .collection-grid--thirds & {
      width: calc(100% / 3);
      max-width: none;
    }
  }
}

.collection-detail--feature {
  width: 100%;
  margin: 0 20px;
  padding: 15px;
  background-color: #e0dfe7;
}

.collection-detail__text {
  padding: 30px;
  font-size: 18px;
  line-height: 2;
  text-align: center;
}

.collection-detail {
    .collection-detail__image-wrap {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #3b1e87;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .collection-detail__image {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .collection-detail__name {
        font-family: $font-titles;
        font-size: 1.5em;
        font-weight: bold;
    }

    .collection-detail__link {
        color: inherit;

        &:hover {
            text-decoration: none;

            .collection-detail__image-wrap::after {
                opacity: 0.3;
            }
        }
    }
}

.collection-detail--overlay {
  position: relative;

  .collection-detail__text {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: 15px;
    color: #fff;
    text-align: center;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
  }

  .collection-detail__name {
    font-size: 1em;
  }
}

@media only screen and (min-width: 720px) {
  .collection-detail--feature {
    .collection-detail__link {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .collection-detail__image-wrap,
    .collection-detail__text {
      flex: 1 1 50%;
      width: 100%;
    }

    .collection-detail__name {
      border-bottom: 1px solid #000;
      padding-bottom: 60px;
      margin-bottom: 60px;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .collection-detail--overlay {
    .collection-detail__name {
      font-size: 1.5em;
    }
  }
}

.collection-ad {
  max-width: 1400px;
  margin: 60px auto;
}

.collection-description {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px;
  font-size: 20px;
}

.collection-description__summary {
  font-size: 1.25em;
  text-align: center;
}

.collection-description__list {
  line-height: 1.75;

  li {
    margin-bottom: 1em;
  }
}

@media only screen and (min-width: 1000px) {
  .collection-description {
    .collection-description__list {
      padding-left: 60px;
    }
  }
}

// --- details
.collection-details {
    max-width: 1000px;
    margin: 0 auto;
    font-size: 18px;
    text-align: center;

    .collection-details__link {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #3b1e87;
            opacity: 0;
            transition: background-color 0.3s ease-in-out;
        }

        &:hover::before {
            opacity: 0.3;
        }
    }

    .collection-details__image-wrap {
        margin-bottom: 60px;
    }

    .collection-details__image {
        margin: 0 auto;
    }

    .collection-details__title {
        font-family: $font-titles;
        display: inline-block;
        padding: 10px;
        border-bottom: 1px solid;
    }

    .collection-details__subtitle {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .collection-details__h4 {
        font-size: 1em;
        font-weight: normal;
    }
}

.collection-details__product {
  margin-bottom: 60px;
}

.collection-details__clarification {
  display: block;
}

// --- related
.collection-related {
  margin-top: 60px;
  text-align: center;

  .collection-details__title {
    display: inline-block;
    padding: 10px;
    border-bottom: 1px solid;
  }
}

.collection-note {
  font-size: 0.75em;

  .collection-details & {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
  }
}

// --- quote
.collection-quote {
  margin: 60px 30px;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
}

.collection-quote__text {
  max-width: 750px;
  margin: 0 auto;
  padding: 0;

  &::before {
    content: "";
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-bottom: 5px;
    background-image: url("/content/themes/milbon/images/sophistone_2021/quote.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.collection-citation {
  font-weight: bold;
  text-transform: uppercase;
}

.collection-citation__title {
  display: block;
  font-weight: normal;
}

@media only screen and (min-width: 1000px) {
  .collection-quote__text {
    padding: 0 30px;
  }
}

// --- video
.collection-video-feature {
  max-width: 1400px;
  margin: 0 auto 30px;
  text-align: center;

  .collection-details__title {
    display: inline-block;
    padding: 10px;
    border-bottom: 1px solid;
  }
}

.landing {
  padding-top: 0 !important;
}

.landing__header,
.landing__teaser {
  display: none !important;
}

.landing__body {
  padding: 0 !important;
}

.collection--sophistone {
    font-size: 16px;

    .collection-description {
        padding: 20px;
        font-size: 1em;
    }

    .collection-details {
        font-size: 1em;
    }

    .collection-detail__text {
        font-size: 1em;
    }

    .collection__title {
        .collection-masthead__text & {
            font-family: "Helvetica", "Arial", sans-serif;
        }
    }

    .collection-summary {
        padding: 20px;
        font-family: "Helvetica", "Arial", sans-serif;
        font-size: 16px;
        line-height: 1.6;
        background-color: #e0dfe7;

        .collection-summary__title {
            font-family: $font-titles;
            font-size: 1.5em;
            font-weight: bold;
            letter-spacing: 4px;
            line-height: 1.35;
        }

        .collection-summary__subtitle {
            font-family: $font-titles;
            font-size: 0.6em;
            font-weight: normal;
        }

        .collection-summary__title--alt {
            font-size: 1.25em;
        }
    }

    .collection-section {
        font-family: "Helvetica", "Arial", sans-serif;
    }

    .collection-section__CC {
        .collection-section & {
            font-family: "Helvetica", "Arial", sans-serif;
        }
    }

    .collection-footer__title {
        .collection-footer & {
            font-family: "Helvetica", "Arial", sans-serif;
        }
    }

    .collection-footer__link {
        font-family: "Helvetica", "Arial", sans-serif;
    }
}