.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main-content__wrap {
	flex: 1 1 auto;
}

.site-footer {
	width: 100%;
}