﻿.milbon-luxuryMainText {
  margin-top: 60px;
  margin-bottom: 60px;
}

.inner-luxurytext {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  color: black;
  font-weight: 400;
}

.luxury-descriptionTitle {
    margin-bottom: 15px;
    font-family: $font-titles;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: black;
}

.lux-ex {
  color: black;
  text-align: right;
  font-size: 7px;
}

.luxury-descriptionText {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px !important;
  color: black;
}

.milbon-luxuryitemlist {
  margin-bottom: 100px;
}

.luxuryitem-title {
    font-family: $font-titles;
    font-size: 20px !important;
    letter-spacing: 1.2px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    color: #000;
}

.luxuryitem-text {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: black;
  margin-top: 20px !important;
}

.luxuryitem-btn {
  text-align: center;
  margin-top: 20px;
}

.lux-btns {
  color: #fff;
  background: #000;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 100;
  padding: 10px 30px;
}

.lux-bg {
  background-color: #f9f0df;
}

.milbon-luxuryimgbxs {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 0;

    img {
        margin: 0 auto;
    }
}

.row.lux-row {
    max-width: 667px;
    margin: 0 auto;
}

@media only screen and (min-width: 680.02px) {
    .row.lux-row {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 768px) {
    .inner-luxurytext {
        font-size: 16px;
    }

    .lux-extp {
        padding: 0 50px;
    }

    h3.luxuryitem-title {
        margin-top: 60px !important;
    }

    .lux-row img {
        .landing & {
            max-width: 275px;
            margin: 0 auto;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .lux-bg {
        margin-right: calc(-100vw / 2 + 1020px / 2);
        margin-left: calc(-100vw / 2 + 1020px / 2);
    }

    .lux-row img {
        .landing & {
            max-width: 100%;
        }
    }
}
