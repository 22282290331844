@font-face {
	font-display: swap;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	src: url('/content/themes/milbon/fonts/dm-sans-v15-latin-regular.woff2') format('woff2'),
		 url('/content/themes/milbon/fonts/dm-sans-v15-latin-regular.woff') format('woff')		 
}

@font-face {
	font-display: swap;
	font-family: "Karla";
	font-style: normal;
	font-weight: 400;
	src: url("/content/themes/milbon/fonts/karla-v31-latin-regular.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-regular.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "Karla";
	font-style: normal;
	font-weight: 500;
	src: url("/content/themes/milbon/fonts/karla-v31-latin-500.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-500.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "Karla";
	font-style: normal;
	font-weight: 700;
	src: url("/content/themes/milbon/fonts/karla-v31-latin-700.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-700.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "Roboto Serif";
	font-style: normal;
	font-weight: 500;
	src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-500.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-500.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "Roboto Serif";
	font-style: normal;
	font-weight: 400;
	src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-regular.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-regular.woff") format("woff");
}

@font-face {
	font-display: swap;
	font-family: "Roboto Serif";
	font-style: italic;
	font-weight: 400;
	src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-italic.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-italic.woff") format("woff");
}
