.registration__message {
	margin-top: 20px;
}

.registration__section-title, .registration__item--contracts h3 {
	margin-top: 2em;
	margin-bottom: 0.75em;
	padding: 0;
	font-family: $font-titles;
	font-size: 1.7em;
	font-weight: normal;
}

.register .registration__subheader {
	margin-top: 2em;
	margin-bottom: 0.5em;
	font-family: $font-titles;
	font-size: 1.25em;
	font-weight: normal;
	text-transform: uppercase;
}

.registration__same-as {
	display: block;
	font-size: 0.6em;
	font-weight: normal;
	font-family: 'Helvetica', 'Arial', sans-serif;
}

.registration__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 600px;
	margin: 20px -5px;
	padding: 0;
	list-style: none;
	overflow: hidden;
}

.registration__label {
	display: block;
	margin-bottom: 3px;
	font-weight: bold;
}

.registration__item {
	width: 100%;
	margin-bottom: 15px;
	padding-right: 5px;
	padding-left: 5px;

	input[type="text"], input[type="number"], input[type="tel"], input[type="email"], select {
		width: 100%;
		padding: 3px 6px;
		line-height: 1.4;
		border: 1px solid #bbb;
		border-radius: 2px;
	}
}

.registration__item--25 {
	width: 50%;
}

.registration__item--40 {
	width: 50%;
}

.label--invisible {
	visibility: hidden;
}

.label--hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(1px 1px 1px 1px); // IE6, IE7
	clip: rect(1px, 1px, 1px, 1px);
}

.registration__note {
	display: block;
	color: #777;
	font-size: 0.8em;
	font-style: italic;
}

.registration__radios {
	label {
		display: inline;
	}
}

@media only screen and (max-width: 479px) {
	.label--invisible {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		clip: rect(1px 1px 1px 1px); // IE6, IE7
		clip: rect(1px, 1px, 1px, 1px);
	}
}

@media only screen and (min-width: 480px) {
	.registration__same-as {
		display: inline;
	}

	.registration__item--25 {
		width: 25%;
	}
	
	.registration__item--40 {
		width: 40%;
	}
	
	.registration__item--50 {
		width: 50%;
	}
	
	.registration__item--60 {
		width: 60%;
	}
	
	.registration__item--75 {
		width: 75%;
	}
}