.faqs {
	padding: 0 10px;
	color: #000;
}

.faq-header {
	max-width: 800px;
	margin: 0 auto;
	padding: 60px 0;
	font-family: 'Helvetica', 'Arial', sans-serif;
	font-size: 14px;
	text-align: center;

	@media only screen and (min-width: 560px) {
		font-size: 24px;
	}
}

.faq-header__title {
	.faqs & {
		margin: 0 0 5px;
		padding: 0;
		font-family: $font-titles;
		font-size: 24px;
		font-weight: normal;

		@media only screen and (min-width: 560px) {
			margin-bottom: 15px;
			font-size: 56px;
		}

		@media only screen and (min-width: 1000px) {
			margin-bottom: 30px;
			font-size: 82px;
		}
	}
}

.faq-category {
	padding-top: 30px;
	text-align: center;
}

.faq-category__title {
	.faqs & {
		margin: 0;
		padding: 0;
		font-family: $font-titles;
		font-size: 24px;
		text-transform: uppercase;

		&::after {
			content: '';
			display: block;
			max-width: 100px;
			height: 1px;
			margin: 15px auto;
			background-color: #ddd;
		}

		@media only screen and (min-width: 560px) {
			font-size: 24px;
		}
	}
}

.faq-list {
	max-width: 600px;
	margin: 0 auto;
	text-align: left;
}

.faq {
	margin: 60px 0;
	line-height: 1.75;
}

.faq__question {
	.faqs & {
		font-weight: normal;
		line-height: 1.5;
	}
}

.faq__answer {
	padding-left: 45px;
}