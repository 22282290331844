.title-wrap {
	padding-top: 5.25em;
}

@media only screen and (max-width: 680px) {
	.title-wrap {
		padding-top: 0;
	}
}

.header-banner__section--left {
	text-align: center;
}

.site-logo {
	margin-top: 0;
	padding-right: 0;
}

.site-logo__link {
	display: block;
	max-width: 180px;
	margin: 0 auto;
}


// --- header top
.header-top {
	background-color: rgba(255, 255, 255, 0.8);
}

.header-top__assistance {
	display: none;
}

.header-top__link {
	margin: 0 0 0 10px;
	padding: 0 0 0 10px;
	color: #000;
	font-size: 11px;
	border-left: none;
}

// temp
.header-banner__section--right {
	display: none;
}

.header-top__account {
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	grid-gap: 7px 14px;

	.header-search {
		grid-column: 2;
		grid-row: 1;
		justify-self: right;
	}

	.header-top__links {
		grid-column: 1 / span 2;
		grid-row: 2;
		align-self: center;
		justify-self: right;
	}
	
	.search {
		margin: 0;
	}
}

@media only screen and (min-width: 720px) {
	.header-top__account {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr auto;
		grid-gap: 7px 14px;

		.header-search {
			grid-column: 3;
			grid-row: 1;
		}

		.header-top__links {
			grid-column: 2;
			grid-row: 1;
			align-self: center;
			justify-self: right;
		}

		.header-top__message {
			display: block;
			grid-column: 1;
			grid-row: 1;
		}

		.search {
			margin: 0;
		}
	}
}