.videos {
	padding-top: 45px;
}

.video-thumb {
	position: relative;
}

.video-thumb__teaser {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	transition: 0.2s ease-in;
}

.video-thumb__teaser-text {
	position: absolute;
	top: 50%;
	width: 100%;
	padding: 15px;
	color: #fff;
	font-size: 24px;
	text-align: center;
	text-transform: capitalize;
	transform: translateY(-50%);
}

.video-thumb:hover .video-thumb__teaser {
	opacity: 1;
}

.video-thumb__image {
	display: block;
	width: 100%;
	height: auto;
	filter: grayscale(90%);
	transition: filter 0.35s ease-in-out;
}

.video-categories {
	padding-top: 45px;
	text-align: center;
}

.video-category {
	margin-bottom: 5px;
}

.video-category__link {
	display: block;
	padding: 10px 20px;
	color: #414042;
	font-size: 16px;
	text-decoration: none;
	text-transform: capitalize;
	border: 1px solid #414042;
}

.video-category__link--active {
	color: #fff;
	background: #414042;
}

.video-category__link:hover {
	text-decoration: none;
}

.video-thumb__link:hover .video-thumb__image, .video-thumb--active .video-thumb__image {
	filter: grayscale(0);
}

.video-thumb__link:hover::after, .video-thumb--active .video-thumb__link::after {
	opacity: 0.8;
}

// .video-thumb--hidden {
// 	display: none;
// }

// .toggle-view-container {
// 	display: flex;
// 	justify-content: center;
// 	margin: 20px 0;
// }

// .toggle-view-button {
// 	background: transparent;
// 	color: #000;
// 	border: none;
// 	font-size: 1.25em;
// 	cursor: pointer;
// }

@media only screen and (min-width: 800px) {
	.videos__thumbs {
		margin: 0 -15px;
		padding: 30px 0 0;
		overflow: hidden;
	}

	.video-thumb {
		float: left;
		width: 33.3333333%;
		padding: 0 15px;
		margin-bottom: 30px;
	}

	.video-thumb:nth-child(3n+1) {
		clear: both;
	}

	.video-thumb__teaser {
		right: 15px;
		left: 15px;
	}
}

@media (max-width: 768px) {
	.video-thumb {
		margin: 20px auto;
	}
	.video-thumb .video-thumb__teaser {
		opacity: 1;
	}
	.video-thumb--active .video-thumb__teaser {
		opacity: 0;
	}
}