@import 'styles/webfonts';

$font-titles: 'DM Sans', sans-serif;
$font-landing-mono: 'Karla', sans-serif;
$font-landing-mono-highlight: 'Roboto Serif', serif;

$purple: #472f91;
$color-highlight: $purple;

a {
	color: $color-highlight;
}

h1 {
	margin-top: 0.75em;
	font-family: $font-titles;
	font-size: 2.5em;
	text-transform: uppercase;
}

hr {
	height: 1px;
	margin: 20px 0;
	border: none;
	border-bottom: 1px solid #ddd;
}

img {
	max-width: 100%;
	height: auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
	max-width: none;
}

.main-content {
	max-width: none;
}

.main-content__inner {
	.pencil {
		max-width: none;
	}

	> div {
		max-width: 1000px;
		margin: 0 auto;
	}
}

.page--default,
.page--landing-default {
	.main-content__inner > div {
		max-width: none;
	}
}

.pencil-banner {
	max-width: none;
}

// --- dev stuff
.dev-message {
	display: none;
}

@import 'styles/buttons';
@import 'styles/header';
@import 'styles/main-content';
@import 'styles/site-nav';
@import 'styles/site-footer';
@import 'styles/breadcrumb';
@import 'styles/page-header';
@import 'styles/product-list';
@import 'styles/quick-view';
@import 'styles/product-details';
@import 'styles/login';
@import 'styles/blog';
@import 'styles/home';
@import 'styles/home-articles';
@import 'styles/home-banners';
@import 'styles/about-us';
@import 'styles/site-locator';
@import 'styles/register';
@import 'styles/collections';
@import 'styles/search';
@import 'styles/faqs';
@import 'styles/gallery';
@import 'styles/landing';
@import 'styles/luxury-landing';