.ui-quickview.ui-dialog {
	border-radius: 0;

	.ui-dialog-titlebar-close {
		z-index: 200;
		margin-top: 0;
		color: #000;
		background: none;
		border: none;
	}

	.ui-dialog-title {
		display: none;
	}

	.ui-dialog-titlebar {
		padding: 0;
		border: none;
	}

	.ui-widget-content {
		padding-top: 25px;
		padding-bottom: 25px;
	}
}
