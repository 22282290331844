.button {
	padding: 10px 20px;
	color: #fff;
	font-family: 'Helvetica', 'Arial', sans-serif;
	font-size: 12px;
	background: $color-highlight;
	border-radius: 0;
}

.RadGrid_Silk .rgEditForm .button {
	color: #fff;
}

.button--large {
	padding: 12px 24px;
}

.button:hover {
	background: #3c277c;
}

.button--line {
	color: #7e7e7e;
	background: #fff;
}

.button--line:hover {
	color: #7e7e7e;
	background: #eee;
}

.button--text {
	color: #005ca0;
	background: none;
	border: none;
}

.button--text:hover {
	color: #005ca0;
	background: none;
	text-decoration: underline;
}

.button--quickview {
	background: $color-highlight;
}

.button--pending {
	&::after {
		background-color: $color-highlight;
	}
}