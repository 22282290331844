@font-face {
  font-display: swap;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/milbon/fonts/dm-sans-v15-latin-regular.woff2") format("woff2"), url("/content/themes/milbon/fonts/dm-sans-v15-latin-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/milbon/fonts/karla-v31-latin-regular.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  src: url("/content/themes/milbon/fonts/karla-v31-latin-500.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-500.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: url("/content/themes/milbon/fonts/karla-v31-latin-700.woff2") format("woff2"), url("/content/themes/milbon/fonts/karla-v31-latin-700.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Serif";
  font-style: normal;
  font-weight: 500;
  src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-500.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-500.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Serif";
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-regular.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto Serif";
  font-style: italic;
  font-weight: 400;
  src: url("/content/themes/milbon/fonts/roboto-serif-v15-latin-italic.woff2") format("woff2"), url("/content/themes/milbon/fonts/roboto-serif-v15-latin-italic.woff") format("woff");
}

a {
  color: #472f91;
}

h1 {
  margin-top: 0.75em;
  font-family: "DM Sans", sans-serif;
  font-size: 2.5em;
  text-transform: uppercase;
}

hr {
  height: 1px;
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #ddd;
}

img {
  max-width: 100%;
  height: auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
  max-width: none;
}

.main-content {
  max-width: none;
}

.main-content__inner .pencil {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.page--default .main-content__inner > div,
.page--landing-default .main-content__inner > div {
  max-width: none;
}

.pencil-banner {
  max-width: none;
}

.dev-message {
  display: none;
}

.button {
  padding: 10px 20px;
  color: #fff;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  background: #472f91;
  border-radius: 0;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button--large {
  padding: 12px 24px;
}

.button:hover {
  background: #3c277c;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--quickview {
  background: #472f91;
}

.button--pending::after {
  background-color: #472f91;
}

.title-wrap {
  padding-top: 5.25em;
}

@media only screen and (max-width: 680px) {
  .title-wrap {
    padding-top: 0;
  }
}

.header-banner__section--left {
  text-align: center;
}

.site-logo {
  margin-top: 0;
  padding-right: 0;
}

.site-logo__link {
  display: block;
  max-width: 180px;
  margin: 0 auto;
}

.header-top {
  background-color: rgba(255, 255, 255, 0.8);
}

.header-top__assistance {
  display: none;
}

.header-top__link {
  margin: 0 0 0 10px;
  padding: 0 0 0 10px;
  color: #000;
  font-size: 11px;
  border-left: none;
}

.header-banner__section--right {
  display: none;
}

.header-top__account {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7px 14px;
}

.header-top__account .header-search {
  grid-column: 2;
  grid-row: 1;
  justify-self: right;
}

.header-top__account .header-top__links {
  grid-column: 1 / span 2;
  grid-row: 2;
  align-self: center;
  justify-self: right;
}

.header-top__account .search {
  margin: 0;
}

@media only screen and (min-width: 720px) {
  .header-top__account {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-gap: 7px 14px;
  }
  .header-top__account .header-search {
    grid-column: 3;
    grid-row: 1;
  }
  .header-top__account .header-top__links {
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    justify-self: right;
  }
  .header-top__account .header-top__message {
    display: block;
    grid-column: 1;
    grid-row: 1;
  }
  .header-top__account .search {
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content__wrap {
  flex: 1 1 auto;
}

.site-footer {
  width: 100%;
}

.sub-nav__header {
  margin: 20px 0 10px;
  padding: 0 5px;
  font-size: 14px;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    max-width: none;
    background-color: transparent;
    border-bottom: 2px solid #000002;
  }
  .site-nav__top {
    color: #000002;
    text-transform: uppercase;
  }
  .site-nav__top:hover {
    background-color: transparent;
  }
  .site-nav__item:hover .site-nav__top {
    text-decoration: underline;
    background-color: transparent;
  }
  .sub-nav {
    margin-top: 0;
    padding-bottom: 5px;
    background-color: #fff;
    border-radius: 0;
    border-top: 2px solid #000002;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  }
  .sub-nav__inner {
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .sub-nav a {
    padding: 5px;
  }
  .sub-nav__header {
    margin: 0 0 10px;
  }
}

.logged-in-only {
  display: none;
}

.is--logged-in .logged-in-only {
  display: inline-block;
}

.site-footer {
  max-width: none;
  padding-bottom: 0;
  color: #7e7c7c;
  border-top: 2px solid #000002;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.site-footer__header {
  display: none;
}

.site-footer a {
  color: inherit;
}

.footer-inner--copyright {
  max-width: none;
  background-color: #000000;
}

.site-footer__copyright {
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
}

.site-footer__copyright a {
  color: #fff;
}

.breadcrumb {
  padding: 15px 10px 10px;
  font-size: 11px;
  text-transform: none;
  border-bottom: 2px solid #ededef;
}

.header-wrap {
  padding-top: 40px;
}

.filters__header {
  padding: 5px 0;
  color: #000;
  background-color: transparent;
  border-radius: 0;
}

.filters__header a {
  color: inherit;
}

.filters__link {
  padding: 5px 0;
}

.filters__link:hover {
  background-color: transparent;
}

.filters__link--selected {
  font-weight: normal;
  text-decoration: underline;
  background-color: transparent;
}

.filters__link--selected:hover {
  font-weight: normal;
  text-decoration: underline;
}

.filters__section {
  margin-bottom: 30px;
}

.filters__clear {
  font-size: 0.85em;
  line-height: 1.25;
}

.filters__section--brands {
  display: none;
}

.page--categories-default .header-wrap h1,
.page--brands-default .header-wrap h1,
.page--lines-default .header-wrap h1 {
  display: none;
}

.product__name {
  font-family: "DM Sans", sans-serif;
}

.for-price {
  display: none;
}

.education-all .for-price {
  display: inline-block;
}

.price__sizes {
  display: none;
}

.product-bar {
  display: none;
}

.product__detail--sku, .product__detail--size {
  display: none;
}

.prod-options-wrap .prod-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.prod-options-wrap .prod-list__item {
  float: none;
  clear: none;
  width: 50%;
}

@media only screen and (min-width: 480px) {
  .prod-options-wrap .prod-list__item {
    width: calc(100% / 3);
  }
}

@media only screen and (min-width: 640px) {
  .prod-options-wrap .prod-list__item {
    width: 25%;
  }
}

.product-bar__bottom {
  display: block;
  background-color: transparent;
}

.page-size, .page-sort {
  display: none;
}

.product-bar__center {
  float: none;
  max-width: 100%;
  margin: 0 auto 30px;
}

.is--logged-out .price__label--sale,
.is--logged-out .price__label--savings {
  display: none;
}

.price__label--highlight {
  background-color: inherit;
  padding: 0px;
  color: #d32121;
  font-size: inherit;
}

.prod-info {
  font-size: 14px;
  line-height: 1.75;
}

.prod-list__description {
  font-size: 12px;
}

.also-like .prod-list__item {
  font-size: 1em;
}

.brand-img img {
  max-height: unset;
}

.ui-quickview.ui-dialog {
  border-radius: 0;
}

.ui-quickview.ui-dialog .ui-dialog-titlebar-close {
  z-index: 200;
  margin-top: 0;
  color: #000;
  background: none;
  border: none;
}

.ui-quickview.ui-dialog .ui-dialog-title {
  display: none;
}

.ui-quickview.ui-dialog .ui-dialog-titlebar {
  padding: 0;
  border: none;
}

.ui-quickview.ui-dialog .ui-widget-content {
  padding-top: 25px;
  padding-bottom: 25px;
}

.prod-info {
  font-size: 14px;
  line-height: 1.75;
}

.prod-info__name {
  font-family: "DM Sans", sans-serif;
  text-transform: none;
}

.prod-info__item--sku,
.prod-info__brand,
.prod-option-sku {
  display: none;
}

.prod-info__header {
  padding-bottom: 1.5em;
  border-bottom: none;
}

.price__label {
  display: none !important;
}

.price__current {
  font-family: "DM Sans", sans-serif;
  font-weight: normal;
}

.accordion__toggle {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
}

.also-like__header, .recently-viewed__header {
  font-family: "DM Sans", sans-serif;
  font-weight: normal;
}

.also-like .prod-list__header, .recently-viewed .prod-list__header {
  margin-bottom: 5px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
}

.for-price--large {
  display: none;
}

.login {
  padding: 0;
}

.login-section__header {
  font-size: 22px;
}

.login-section__description {
  font-size: 16px;
}

.disclaimer {
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.login-form__label {
  font-family: "DM Sans", sans-serif;
  text-transform: uppercase;
}

.disclaimer-container {
  margin: 20px auto;
}

.disclaimer-container .milbon-disclaimer {
  margin-bottom: 0;
  text-align: center;
}

.post__header {
  line-height: 2.25;
}

.post__text--list {
  text-align: center;
  border-bottom: none;
}

.post__text--list .post__img {
  float: none;
  width: 100%;
  padding: 0;
}

.post__text--list .post__title {
  font-size: 1.5em;
  line-height: 1.25;
  text-align: center;
}

.post__text--list .post__link {
  display: none;
}

.post__text--list .post__more {
  display: none;
}

.post__text--list .post__body {
  padding-right: 5px;
  padding-left: 5px;
}

.post__tertiary {
  padding-top: 30px;
  padding-bottom: 30px;
}

.fader, .cloud {
  display: none;
}

@media only screen and (min-width: 520px) {
  .post__text--list {
    float: left;
    width: calc(100% / 2);
  }
  .post__text--list:nth-child(2n+1) {
    clear: left;
  }
  .fader {
    display: block;
  }
}

@media only screen and (min-width: 740px) {
  .post__text--list {
    width: calc(100% / 3);
  }
  .post__text--list:nth-child(2n+1) {
    clear: none;
  }
  .post__text--list:nth-child(3n+1) {
    clear: left;
  }
}

@media only screen and (max-width: 640px) {
  .post__secondary {
    clear: both;
  }
  .post__primary {
    padding: 0 10px;
  }
}

@media only screen and (min-width: 620px) {
  .post__primary {
    padding: 0 25px 0 0;
  }
}

.page--default .main-content {
  max-width: none;
}

.salon-locator-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  justify-content: center;
  align-items: center;
}

.salon-locator-container img {
  max-width: 100%;
  height: auto;
}

.locator-text-container {
  margin: 20px;
}

h2.locator-header {
  font-size: 3.25em;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}

p.locator-text {
  font-size: 1.75em;
}

.locator-link-container {
  margin-top: 50px;
}

a.locator-link {
  background: #000;
  color: #fff;
  font-size: 1.5em;
  padding: 20px;
}

@media (max-width: 768px) {
  .locator-text-container {
    text-align: center;
  }
  .locator-img-container {
    justify-self: center;
  }
}

.faux-main-banner {
  position: relative;
  margin-bottom: 20px;
}

.faux-main-banner .responsive-video {
  padding-top: 41.71875%;
}

.faux-main-banner__link {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0);
}

.home-articles {
  max-width: 1000px;
  margin: 0 auto;
}

.home-articles__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.home-articles__item {
  float: none;
  width: 100%;
}

.home-article__text {
  text-align: center;
}

.home-article__text p {
  color: inherit;
  font-size: 12px;
  line-height: 1.5;
}

.home-article__link {
  color: inherit;
}

.home-article__title {
  margin-bottom: 15px;
  font-family: "DM Sans", sans-serif;
  font-size: 30px;
  font-weight: normal;
  text-transform: none;
}

.home-articles__more {
  display: none;
}

@media only screen and (min-width: 540px) {
  .home-articles__item {
    width: 50%;
  }
}

@media only screen and (min-width: 680px) {
  .home-articles__item {
    width: calc(100% / 3);
  }
}

.main-banner-wrap {
  margin-bottom: 20px;
}

.standard-banners {
  max-width: 1000px;
  margin: 0 auto;
}

.standard-banner {
  margin: 15px 0;
}

.standard-banners--secondary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.standard-banners--secondary .standard-banner {
  width: 50%;
  padding: 0 10px;
}

.standard-banners--tertiary {
  width: 80%;
}

.about-summary {
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 15px;
  font-family: 'Garamond', serif;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

.about-summary .about-summary__text {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 640px) {
  .about-summary {
    padding: 60px 30px;
    font-size: 28px;
  }
}

.img--full {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

@media only screen and (min-width: 1000px) {
  .about-full {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
  }
}

.page--about-us-default .page-header {
  display: none;
}

.page--about-us-default .page-wrap {
  padding-top: 45px;
}

.page--about-us-default .filters__header {
  font-size: 1.25em;
}

.locator {
  display: flex;
  flex-direction: column;
}

.locator__inner {
  position: static;
  order: 1;
  width: auto;
  margin-top: 15px;
}

.locator-results__inner {
  border: 1px solid #c8cfd1;
  background-color: #fff;
  border-radius: 3px;
}

.locator-results {
  max-height: 15em;
  overflow-y: auto;
  border: none;
  border-radius: 0;
}

.locator__map {
  order: 2;
}

@media only screen and (min-width: 520px) {
  .locator-results__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .locator-results__item {
    width: 50%;
  }
}

@media only screen and (min-width: 780px) {
  .locator__inner {
    margin-top: 0;
  }
  .locator__map {
    order: 2;
  }
  .locator-results-wrapper {
    position: absolute;
    top: 70px;
    right: 10px;
    width: 325px;
    z-index: 300;
  }
  .locator-results {
    max-height: 31em;
  }
  .locator-results__list {
    display: block;
  }
  .locator-results__item {
    width: auto;
  }
}

.locator__count {
  padding-bottom: 0;
  border: none;
}

.locator-input {
  display: inline-flex;
  margin-bottom: 15px;
  padding: 0;
  border: none;
  background-color: transparent;
}

.locator-input__values {
  align-items: center;
  border: 1px solid #c8cfd1;
  border-radius: 0;
  background-color: transparent;
}

.locator-input__location, .locator-input__range, .locator-input__submit {
  border: none;
}

.locator-input__location {
  padding-left: 15px !important;
  border-right: 1px solid #c8cfd1;
}

.locator-input__range {
  padding: 0 10px !important;
}

.locator-input__submit {
  padding: 0 0 0 5px;
}

.locator-search__submit {
  width: auto;
  height: auto;
  padding: 5px 15px;
  color: #472f91;
  font-size: 11px;
  background: none;
  border: 1px solid #472f91;
  text-transform: uppercase;
  text-indent: 0;
  white-space: normal;
  cursor: pointer;
}

@media only screen and (min-width: 380px) {
  .locator-search__submit {
    padding: 15px 30px;
    font-size: 12px;
  }
}

.locator-search__range {
  display: block;
  font-size: 12px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("/content/themes/milbon/images/select-arrow.png");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}

.locator-search__range::-ms-expand {
  display: none;
}

.locator-search__range:hover {
  border-color: #888;
}

.locator-search__range:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.locator-search__range option {
  font-weight: normal;
}

.registration__message {
  margin-top: 20px;
}

.registration__section-title, .registration__item--contracts h3 {
  margin-top: 2em;
  margin-bottom: 0.75em;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 1.7em;
  font-weight: normal;
}

.register .registration__subheader {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-family: "DM Sans", sans-serif;
  font-size: 1.25em;
  font-weight: normal;
  text-transform: uppercase;
}

.registration__same-as {
  display: block;
  font-size: 0.6em;
  font-weight: normal;
  font-family: 'Helvetica', 'Arial', sans-serif;
}

.registration__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 600px;
  margin: 20px -5px;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.registration__label {
  display: block;
  margin-bottom: 3px;
  font-weight: bold;
}

.registration__item {
  width: 100%;
  margin-bottom: 15px;
  padding-right: 5px;
  padding-left: 5px;
}

.registration__item input[type="text"], .registration__item input[type="number"], .registration__item input[type="tel"], .registration__item input[type="email"], .registration__item select {
  width: 100%;
  padding: 3px 6px;
  line-height: 1.4;
  border: 1px solid #bbb;
  border-radius: 2px;
}

.registration__item--25 {
  width: 50%;
}

.registration__item--40 {
  width: 50%;
}

.label--invisible {
  visibility: hidden;
}

.label--hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.registration__note {
  display: block;
  color: #777;
  font-size: 0.8em;
  font-style: italic;
}

.registration__radios label {
  display: inline;
}

@media only screen and (max-width: 479px) {
  .label--invisible {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }
}

@media only screen and (min-width: 480px) {
  .registration__same-as {
    display: inline;
  }
  .registration__item--25 {
    width: 25%;
  }
  .registration__item--40 {
    width: 40%;
  }
  .registration__item--50 {
    width: 50%;
  }
  .registration__item--60 {
    width: 60%;
  }
  .registration__item--75 {
    width: 75%;
  }
}

.collection-brand {
  font-weight: normal;
  text-transform: uppercase;
}

@media only screen and (min-width: 1000px) {
  .collection {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
  }
}

.collection-masthead {
  position: relative;
}

.collection-masthead .collection-masthead__footnote {
  max-width: 1400px;
  margin: 10px auto 0 10px;
  padding: 0;
  font-size: 11px;
}

@media only screen and (min-width: 1000px) {
  .collection-masthead .collection-masthead__footnote {
    margin-left: 0;
  }
}

.collection-masthead__text {
  position: relative;
  top: 50%;
  right: 0;
  left: 0;
  margin: 140px auto 0;
  padding: 0 15px;
  color: #fff;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
}

.collection--sophistone .collection-masthead__text {
  position: static;
  padding: 15px 15px 0;
  background-color: #e0dfe7;
  text-shadow: none;
  transform: none;
}

@media only screen and (min-width: 720px) {
  .collection--sophistone .collection-masthead__text {
    position: absolute;
    top: auto;
    bottom: 15px;
    padding: 0;
    background-color: transparent;
  }
}

.collection-masthead__text .collection__title {
  margin: 0 0 5px;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: normal;
}

.collection-masthead .collection-creator__header {
  display: inline-block;
  margin: 0 auto;
  padding-top: 6px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  border-top: 1px solid;
}

.collection-masthead .collection-creator__header span {
  font-weight: normal;
}

.collection--sophistone .collection-masthead .collection-creator__header {
  color: #000;
}

.collection-masthead__logo {
  margin: 0 auto;
}

.collection__feature-img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

@media only screen and (min-width: 560px) {
  .collection-masthead__text {
    text-shadow: none;
  }
  .collection-masthead__text .collection__title {
    margin-bottom: 0;
    font-size: 56px;
  }
}

@media only screen and (min-width: 1000px) {
  .collection-masthead__text {
    top: 85%;
    font-size: 37px;
  }
  .collection-masthead__text .collection__title {
    margin-bottom: 0;
    font-size: 44px;
  }
}

.collection-summary {
  padding: 30px 15px;
  font-family: "Garamond", serif;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
}

.collection-summary .collection-summary__inner p {
  margin: 0;
  padding: 0;
}

.collection-summary .collection-summary__title {
  font-size: 2em;
  letter-spacing: 8px;
}

.collection-summary .collection-summary__title--alt {
  font-size: 1.25em;
  letter-spacing: 1px;
}

.collection-summary .collection-summary__subtitle {
  display: block;
  margin-top: 15px;
  font-size: 0.4em;
  text-transform: none;
  letter-spacing: 0;
}

.collection-summary__inner {
  max-width: 750px;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (min-width: 640px) {
  .collection-summary {
    padding: 60px 30px;
    font-size: 28px;
  }
}

@media only screen and (min-width: 1000px) {
  .collection-summary {
    padding: 60px;
    line-height: 1.75;
  }
  .collection-summary__inner {
    padding: 0 30px;
  }
}

.collection-section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}

.collection-section--info {
  display: block;
  max-width: none;
}

.collection-video {
  padding: 30px 0;
}

.collection-section__full {
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;
}

.collection-section__left {
  padding: 15px;
}

.collection-section__right {
  padding: 15px;
}

.collection-section__title {
  font-family: "DM Sans", sans-serif;
}

.collection-section .collection-section__title {
  margin: 0 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
}

.collection-section__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.collection-section .collection-section__note {
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 540px) {
  .collection-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .collection-section__left,
  .collection-section__right {
    padding: 0 10px;
    flex: 1 1 50%;
  }
}

@media only screen and (min-width: 780px) {
  .collection-section {
    font-size: 14px;
    line-height: 1.75;
  }
  .collection-section .collection-section__title {
    margin-bottom: 30px;
    font-size: 44px;
  }
}

.collection-button {
  padding: 20px 30px;
}

.collection-footer {
  padding: 60px 0;
  text-align: center;
}

.collection-footer .collection-footer__title {
  margin: 0 0 5px;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
}

.collection-footer__link {
  display: block;
  padding: 0 5px;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
}

.collection-footer__link-name {
  margin: 15px 0 0 0;
}

.collection-footer__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.collection-footer .bx-pager {
  display: none;
}

.collection-footer .bx-wrapper {
  max-width: 700px;
}

.collection-footer .bx-wrapper .bx-controls-direction a {
  top: 40%;
  width: 46px;
  height: 46px;
  margin-top: -23px;
  background-image: url("/content/themes/milbon/images/about-slider-arrows.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}

.collection-footer .bx-wrapper .bx-controls-direction a.bx-prev {
  left: -70px;
}

.collection-footer .bx-wrapper .bx-controls-direction a.bx-next {
  right: -70px;
  background-position: -46px 0;
}

@media only screen and (min-width: 720px) {
  .collection-footer .collection-footer__title {
    margin-bottom: 30px;
    font-size: 38px;
  }
}

.collection--antifrizz .collection-masthead__text {
  color: #f07b25;
}

.collection--antifrizz .collection-summary {
  background-color: #fdf2e9;
}

.collection--antifrizz .collection-section--info {
  background-color: #fdf2e9;
}

.collection--antifrizz .collection-button {
  background-color: #f07b25;
}

.collection--antifrizz .collection-button:hover {
  background-color: #d3620f;
}

.collection--moisture .collection-masthead__text {
  color: #007130;
}

.collection--moisture .collection-summary {
  background-color: #e6f1eb;
}

.collection--moisture .collection-section--info {
  background-color: #e6f1eb;
}

.collection--moisture .collection-button {
  background-color: #007130;
}

.collection--moisture .collection-button:hover {
  background-color: #003e1a;
}

.collection--repair .collection-masthead__text {
  color: #a4004a;
}

.collection--repair .collection-summary {
  background-color: #f6e5ed;
}

.collection--repair .collection-section--info {
  background-color: #f6e5ed;
}

.collection--repair .collection-button {
  background-color: #a4004a;
}

.collection--repair .collection-button:hover {
  background-color: #710033;
}

.collection--scalp .collection-masthead__text {
  color: #55afad;
}

.collection--scalp .collection-summary {
  background-color: #eff7f9;
}

.collection--scalp .collection-section--info {
  background-color: #eff7f9;
}

.collection--scalp .collection-button {
  background-color: #55afad;
}

.collection--scalp .collection-button:hover {
  background-color: #438e8c;
}

.collection--smooth .collection-masthead__text {
  color: #004f9d;
}

.collection--smooth .collection-summary {
  background-color: #e6edf7;
}

.collection--smooth .collection-section--info {
  background-color: #e6edf7;
}

.collection--smooth .collection-button {
  background-color: #004f9d;
}

.collection--smooth .collection-button:hover {
  background-color: #00356a;
}

.collection--volume .collection-masthead__text {
  color: #a25fa3;
}

.collection--volume .collection-summary {
  background-color: #f6eff7;
}

.collection--volume .collection-section--info {
  background-color: #f6eff7;
}

.collection--volume .collection-button {
  background-color: #a25fa3;
}

.collection--volume .collection-button:hover {
  background-color: #834c83;
}

.collection--5-week .collection-masthead {
  max-width: 1400px;
  margin: 0 auto;
}

.collection--5-week .collection-masthead__text {
  top: auto;
  right: 0;
  bottom: 30px;
  left: auto;
  width: 30.6%;
  max-width: none;
  margin: 0;
  padding: 0 30px;
  text-align: left;
  transform: none;
}

.collection--5-week .collection__title {
  margin-bottom: 0;
  color: #fff;
  font-family: "Garamond", serif;
  font-size: 24px;
  text-transform: none;
}

@media only screen and (min-width: 1150px) {
  .collection--5-week .collection__title span {
    display: block;
  }
}

@media only screen and (max-width: 620px) {
  .collection-masthead__text {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--5-week .collection__title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 720px) {
  .collection--5-week .collection__title {
    font-size: 52px;
  }
}

.collection--360-degree .collection-masthead {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.collection--360-degree .collection-masthead__text {
  position: absolute;
  top: auto;
  bottom: 0;
  width: 56.55%;
  max-width: none;
  margin-right: 0;
  padding: 0 30px;
  color: #000;
  text-align: left;
  text-shadow: none;
  transform: none;
}

.collection--360-degree .collection__title {
  margin-bottom: 5px;
  font-size: 24px;
  text-transform: none;
}

.collection--360-degree .collection-summary__title {
  font-size: 18px;
}

@media only screen and (min-width: 560px) {
  .collection--360-degree .collection__title {
    margin-bottom: 10px;
    font-size: 36px;
  }
  .collection--360-degree .collection__title span {
    display: block;
  }
  .collection--360-degree .collection-summary__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--360-degree .collection-masthead__text {
    font-size: 16px;
  }
  .collection--360-degree .collection__title {
    margin-bottom: 15px;
    font-size: 32px;
  }
  .collection--360-degree .collection-summary__title {
    font-size: 52px;
  }
}

@media only screen and (min-width: 840px) {
  .collection--360-degree .collection__title {
    font-size: 52px;
  }
}

.collection--about .collection-masthead__text {
  position: static;
  padding: 15px;
  font-size: 14px;
  text-shadow: none;
  transform: none;
}

.collection--about .collection__title {
  margin-bottom: 5px;
  font-family: "Garamond", serif;
  font-size: 24px;
  text-transform: none;
}

.collection--about .collection-summary {
  font-size: 14px;
}

.collection--about .collection-summary__title {
  font-size: 18px;
}

@media only screen and (min-width: 560px) {
  .collection--about .collection-masthead__text {
    position: absolute;
    width: 54%;
    max-width: none;
    margin-right: 0;
    padding: 0 30px;
    color: #fff;
    transform: translateY(-50%);
  }
  .collection--about .collection__title {
    margin-bottom: 10px;
    font-size: 36px;
  }
  .collection--about .collection-summar {
    font-size: 18px;
  }
  .collection--about .collection-summary__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--about .collection-masthead__text {
    padding: 0 60px;
    font-size: 14px;
  }
  .collection--about .collection__title {
    margin-bottom: 15px;
    font-size: 52px;
  }
  .collection--about .collection-summary {
    font-size: 24px;
  }
  .collection--about .collection-summary__title {
    font-size: 52px;
  }
}

@media only screen and (min-width: 560px) {
  .collection--about .collection-masthead__text {
    font-size: 16px;
  }
}

@media only screen and (min-width: 820px) {
  .collection--about .collection-masthead__text {
    transform: none;
    font-size: 14px;
  }
}

.collection-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1040px;
  margin: 60px auto;
}

.collection-grid--wide {
  max-width: 1400px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.collection-grid .collection-quote {
  order: 3;
  width: 100%;
}

.collection-grid .collection-detail:nth-child(n + 3) {
  order: 4;
}

@media only screen and (min-width: 720px) {
  .collection-grid .collection-quote,
  .collection-grid .collection-detail {
    order: unset !important;
  }
}

.collection-detail {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  padding: 0 10px;
}

.collection-grid--thirds .collection-detail {
  max-width: 350px;
}

@media only screen and (min-width: 720px) {
  .collection-detail {
    width: 50%;
    max-width: none;
    margin-bottom: 40px;
    padding: 0 20px;
  }
  .collection-grid--thirds .collection-detail {
    width: calc(100% / 3);
    max-width: none;
  }
}

.collection-detail--feature {
  width: 100%;
  margin: 0 20px;
  padding: 15px;
  background-color: #e0dfe7;
}

.collection-detail__text {
  padding: 30px;
  font-size: 18px;
  line-height: 2;
  text-align: center;
}

.collection-detail .collection-detail__image-wrap {
  position: relative;
}

.collection-detail .collection-detail__image-wrap::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3b1e87;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.collection-detail .collection-detail__image {
  display: block;
  max-width: 100%;
  height: auto;
}

.collection-detail .collection-detail__name {
  font-family: "DM Sans", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
}

.collection-detail .collection-detail__link {
  color: inherit;
}

.collection-detail .collection-detail__link:hover {
  text-decoration: none;
}

.collection-detail .collection-detail__link:hover .collection-detail__image-wrap::after {
  opacity: 0.3;
}

.collection-detail--overlay {
  position: relative;
}

.collection-detail--overlay .collection-detail__text {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  padding: 15px;
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 2px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
}

.collection-detail--overlay .collection-detail__name {
  font-size: 1em;
}

@media only screen and (min-width: 720px) {
  .collection-detail--feature .collection-detail__link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .collection-detail--feature .collection-detail__image-wrap,
  .collection-detail--feature .collection-detail__text {
    flex: 1 1 50%;
    width: 100%;
  }
  .collection-detail--feature .collection-detail__name {
    border-bottom: 1px solid #000;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1000px) {
  .collection-detail--overlay .collection-detail__name {
    font-size: 1.5em;
  }
}

.collection-ad {
  max-width: 1400px;
  margin: 60px auto;
}

.collection-description {
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px;
  font-size: 20px;
}

.collection-description__summary {
  font-size: 1.25em;
  text-align: center;
}

.collection-description__list {
  line-height: 1.75;
}

.collection-description__list li {
  margin-bottom: 1em;
}

@media only screen and (min-width: 1000px) {
  .collection-description .collection-description__list {
    padding-left: 60px;
  }
}

.collection-details {
  max-width: 1000px;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
}

.collection-details .collection-details__link {
  position: relative;
  display: inline-block;
}

.collection-details .collection-details__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3b1e87;
  opacity: 0;
  transition: background-color 0.3s ease-in-out;
}

.collection-details .collection-details__link:hover::before {
  opacity: 0.3;
}

.collection-details .collection-details__image-wrap {
  margin-bottom: 60px;
}

.collection-details .collection-details__image {
  margin: 0 auto;
}

.collection-details .collection-details__title {
  font-family: "DM Sans", sans-serif;
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid;
}

.collection-details .collection-details__subtitle {
  margin-top: 1em;
  margin-bottom: 1em;
}

.collection-details .collection-details__h4 {
  font-size: 1em;
  font-weight: normal;
}

.collection-details__product {
  margin-bottom: 60px;
}

.collection-details__clarification {
  display: block;
}

.collection-related {
  margin-top: 60px;
  text-align: center;
}

.collection-related .collection-details__title {
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid;
}

.collection-note {
  font-size: 0.75em;
}

.collection-details .collection-note {
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
}

.collection-quote {
  margin: 60px 30px;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
}

.collection-quote__text {
  max-width: 750px;
  margin: 0 auto;
  padding: 0;
}

.collection-quote__text::before {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-bottom: 5px;
  background-image: url("/content/themes/milbon/images/sophistone_2021/quote.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.collection-citation {
  font-weight: bold;
  text-transform: uppercase;
}

.collection-citation__title {
  display: block;
  font-weight: normal;
}

@media only screen and (min-width: 1000px) {
  .collection-quote__text {
    padding: 0 30px;
  }
}

.collection-video-feature {
  max-width: 1400px;
  margin: 0 auto 30px;
  text-align: center;
}

.collection-video-feature .collection-details__title {
  display: inline-block;
  padding: 10px;
  border-bottom: 1px solid;
}

.landing {
  padding-top: 0 !important;
}

.landing__header,
.landing__teaser {
  display: none !important;
}

.landing__body {
  padding: 0 !important;
}

.collection--sophistone {
  font-size: 16px;
}

.collection--sophistone .collection-description {
  padding: 20px;
  font-size: 1em;
}

.collection--sophistone .collection-details {
  font-size: 1em;
}

.collection--sophistone .collection-detail__text {
  font-size: 1em;
}

.collection-masthead__text .collection--sophistone .collection__title {
  font-family: "Helvetica", "Arial", sans-serif;
}

.collection--sophistone .collection-summary {
  padding: 20px;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  background-color: #e0dfe7;
}

.collection--sophistone .collection-summary .collection-summary__title {
  font-family: "DM Sans", sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 4px;
  line-height: 1.35;
}

.collection--sophistone .collection-summary .collection-summary__subtitle {
  font-family: "DM Sans", sans-serif;
  font-size: 0.6em;
  font-weight: normal;
}

.collection--sophistone .collection-summary .collection-summary__title--alt {
  font-size: 1.25em;
}

.collection--sophistone .collection-section {
  font-family: "Helvetica", "Arial", sans-serif;
}

.collection-section .collection--sophistone .collection-section__CC {
  font-family: "Helvetica", "Arial", sans-serif;
}

.collection-footer .collection--sophistone .collection-footer__title {
  font-family: "Helvetica", "Arial", sans-serif;
}

.collection--sophistone .collection-footer__link {
  font-family: "Helvetica", "Arial", sans-serif;
}

.header-top__search {
  margin-bottom: 10px;
  overflow: hidden;
}

.search {
  max-width: 150px;
  margin: 5px 5px 5px auto;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 3px;
}

.search__options {
  display: none;
}

.search__text {
  border-left: none;
}

.search__field {
  color: #fff;
  background: none;
}

@media only screen and (min-width: 820px) {
  .search {
    float: right;
    margin: 7px 0 0 14px;
  }
}

.faqs {
  padding: 0 10px;
  color: #000;
}

.faq-header {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  text-align: center;
}

@media only screen and (min-width: 560px) {
  .faq-header {
    font-size: 24px;
  }
}

.faqs .faq-header__title {
  margin: 0 0 5px;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: normal;
}

@media only screen and (min-width: 560px) {
  .faqs .faq-header__title {
    margin-bottom: 15px;
    font-size: 56px;
  }
}

@media only screen and (min-width: 1000px) {
  .faqs .faq-header__title {
    margin-bottom: 30px;
    font-size: 82px;
  }
}

.faq-category {
  padding-top: 30px;
  text-align: center;
}

.faqs .faq-category__title {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  text-transform: uppercase;
}

.faqs .faq-category__title::after {
  content: '';
  display: block;
  max-width: 100px;
  height: 1px;
  margin: 15px auto;
  background-color: #ddd;
}

@media only screen and (min-width: 560px) {
  .faqs .faq-category__title {
    font-size: 24px;
  }
}

.faq-list {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.faq {
  margin: 60px 0;
  line-height: 1.75;
}

.faqs .faq__question {
  font-weight: normal;
  line-height: 1.5;
}

.faq__answer {
  padding-left: 45px;
}

.videos {
  padding-top: 45px;
}

.video-thumb {
  position: relative;
}

.video-thumb__teaser {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: 0.2s ease-in;
}

.video-thumb__teaser-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 15px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
  transform: translateY(-50%);
}

.video-thumb:hover .video-thumb__teaser {
  opacity: 1;
}

.video-thumb__image {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(90%);
  transition: filter 0.35s ease-in-out;
}

.video-categories {
  padding-top: 45px;
  text-align: center;
}

.video-category {
  margin-bottom: 5px;
}

.video-category__link {
  display: block;
  padding: 10px 20px;
  color: #414042;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  border: 1px solid #414042;
}

.video-category__link--active {
  color: #fff;
  background: #414042;
}

.video-category__link:hover {
  text-decoration: none;
}

.video-thumb__link:hover .video-thumb__image, .video-thumb--active .video-thumb__image {
  filter: grayscale(0);
}

.video-thumb__link:hover::after, .video-thumb--active .video-thumb__link::after {
  opacity: 0.8;
}

@media only screen and (min-width: 800px) {
  .videos__thumbs {
    margin: 0 -15px;
    padding: 30px 0 0;
    overflow: hidden;
  }
  .video-thumb {
    float: left;
    width: 33.3333333%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .video-thumb:nth-child(3n+1) {
    clear: both;
  }
  .video-thumb__teaser {
    right: 15px;
    left: 15px;
  }
}

@media (max-width: 768px) {
  .video-thumb {
    margin: 20px auto;
  }
  .video-thumb .video-thumb__teaser {
    opacity: 1;
  }
  .video-thumb--active .video-thumb__teaser {
    opacity: 0;
  }
}

.landing__header {
  display: none;
}

.landing-mono {
  font-family: "Karla", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0 auto;
  max-width: 1920px;
}

.landing .landing-mono h1 {
  font-size: 29px;
  font-weight: 500;
  max-width: 650px;
  margin: 0 auto;
  padding-top: 85px;
}

.landing .landing-mono h2 {
  font-size: 40px;
  font-family: "Roboto Serif", serif;
  font-weight: 500;
  text-align: center;
  padding-bottom: 50px;
  margin: 55px 0 0;
}

.landing .landing-mono h3 {
  font-size: 40px;
  font-family: "Roboto Serif", serif;
  font-weight: 500;
  margin: 0.5em 0;
}

.landing .landing-mono ul {
  padding: 0;
}

.landing-mono li {
  list-style-type: none;
  padding: 15px 0;
}

.landing-mono a {
  color: unset;
  text-decoration: underline;
}

.landing-mono .row {
  margin: 0 auto;
  max-width: 1000px;
  padding-top: 65px;
}

.landing-mono picture {
  margin: 85px auto 0;
}

.landing-highlight__container {
  background-color: #e4dfd2;
}

.landing-highlight {
  max-width: 1000px;
  margin: 0 auto;
}

.landing-highlight__item {
  padding: 0;
  background-color: #fff;
}

.landing-highlight__item:first-of-type {
  color: #1c528f;
}

.landing-highlight__item:nth-child(2) {
  color: #912c1e;
}

.landing-highlight__item:last-of-type {
  color: #676e2f;
}

.landing-highlight__item:last-of-type .landing-highlight__label {
  border-right: none;
}

.landing-highlight__label {
  border-right: 1px solid black;
  font-size: 22px;
  line-height: 1.1;
  font-family: "Roboto Serif", serif;
  padding: 20px;
}

.landing-highlight__label span {
  font-style: italic;
}

.landing-highlight__header {
  display: inline-flex;
  width: 100%;
  justify-content: start;
  align-items: baseline;
  text-transform: none;
  gap: 7px;
  margin-bottom: 50px;
}

.landing-highlight__text {
  max-width: 650px;
  margin: 0 auto;
}

.landing-highlight__text--strong {
  font-weight: 700;
}

.mono-artist__quote {
  font-size: 36px;
  font-weight: 500;
}

.mono-artist__quotep {
  line-height: 1.2;
}

.mono-artist__name {
  font-size: 24px;
  font-weight: 500;
}

.mono-rock .row {
  padding-bottom: 65px;
}

.mono-ingredients p {
  font-size: 24px;
  font-weight: 500;
}

.mono-ingredient__note {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: center;
}

.mono-ingredient__note-header {
  font-weight: 500;
}

.mono-videos img {
  margin: 85px auto 0;
}

@media only screen and (max-width: 819.98px) {
  .landing-mono {
    /*.landing & h1 {
            padding-top: 50px;
        }*/
    /*.landing & h3 {
            font-size: 30px;
        }*/
    /* picture {
            margin-top: 50px;
        }*/
    /*.landing & ul {
            padding: 0;
        }

        li {
            list-style-type: none;
            padding: 15px 0;
        }*/
    /*.row {
            padding-top: 50px;
        }*/
  }
  .landing .landing-mono h2 {
    font-size: 36px;
  }
  .landing-mono .mono-artist__quote {
    font-size: 30px;
  }
}

@media only screen and (max-width: 680px) {
  .landing-mono {
    /*font-family: $font-landing-mono;
        font-size: 18px;
        color: #000;
        margin: 0 auto;
        max-width: 1920px;*/
    /*.landing & ul {
            padding: 0;
        }

        li {
            list-style-type: none;
            padding: 15px 0;
        }*/
  }
  .landing .landing-mono h1 {
    padding-top: 50px;
  }
  .landing .landing-mono h2 {
    font-size: 28px;
  }
  .landing .landing-mono h3 {
    font-size: 30px;
  }
  .landing-mono picture {
    margin-top: 50px;
  }
  .landing-mono .row {
    padding-top: 50px;
  }
  .landing-highlight__container {
    padding: 0 8px;
  }
  .landing-highlight__header {
    text-align: center;
    display: block;
  }
  .landing-highlight__header img {
    margin: 5px auto;
  }
  .landing-highlight__label {
    font-size: 15px;
    padding: 15px 10px;
    line-height: 1.2;
  }
  .landing .mono-artist__quote {
    padding-top: 10px;
    font-size: 26px;
  }
  .mono-artist__name {
    font-size: 22px;
  }
  .landing .mono-rock .g-7,
  .landing .mono-ingredients p {
    padding-top: 10px;
  }
  .mono-rock .row {
    padding-bottom: 40px;
  }
  .mono-videos img {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 680.02px) {
  .mono-artist .row {
    display: flex;
    align-items: center;
    padding: 25px 0 0;
  }
  .mono-artist .g-8 {
    padding: 0 20px;
  }
  .mono-rock .row {
    display: flex;
    align-items: center;
    padding: 95px 0;
  }
  .mono-rock .g-7 {
    padding-left: 50px;
  }
  .mono-ingredients .row {
    display: flex;
    align-items: center;
  }
  .mono-ingredients .row > div:last-of-type {
    padding-right: 60px;
    padding-left: 10px;
  }
  .mono-ingredients .g-6 {
    padding: 0;
  }
  .mono-ingredients ul {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 820px) {
  .mono-rock .g-7 {
    padding-left: 50px;
  }
}

@media only screen and (min-width: 1000px) {
  .mono-ingredients .row > div:last-of-type {
    padding-left: 0;
  }
}

.milbon-luxuryMainText {
  margin-top: 60px;
  margin-bottom: 60px;
}

.inner-luxurytext {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  color: black;
  font-weight: 400;
}

.luxury-descriptionTitle {
  margin-bottom: 15px;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: black;
}

.lux-ex {
  color: black;
  text-align: right;
  font-size: 7px;
}

.luxury-descriptionText {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px !important;
  color: black;
}

.milbon-luxuryitemlist {
  margin-bottom: 100px;
}

.luxuryitem-title {
  font-family: "DM Sans", sans-serif;
  font-size: 20px !important;
  letter-spacing: 1.2px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  color: #000;
}

.luxuryitem-text {
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: black;
  margin-top: 20px !important;
}

.luxuryitem-btn {
  text-align: center;
  margin-top: 20px;
}

.lux-btns {
  color: #fff;
  background: #000;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 100;
  padding: 10px 30px;
}

.lux-bg {
  background-color: #f9f0df;
}

.milbon-luxuryimgbxs {
  max-width: 1000px;
  margin: 0 auto;
  padding: 50px 0;
}

.milbon-luxuryimgbxs img {
  margin: 0 auto;
}

.row.lux-row {
  max-width: 667px;
  margin: 0 auto;
}

@media only screen and (min-width: 680.02px) {
  .row.lux-row {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .inner-luxurytext {
    font-size: 16px;
  }
  .lux-extp {
    padding: 0 50px;
  }
  h3.luxuryitem-title {
    margin-top: 60px !important;
  }
  .landing .lux-row img {
    max-width: 275px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1000px) {
  .lux-bg {
    margin-right: calc(-100vw / 2 + 1020px / 2);
    margin-left: calc(-100vw / 2 + 1020px / 2);
  }
  .landing .lux-row img {
    max-width: 100%;
  }
}
