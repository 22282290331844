.site-footer {
	max-width: none;
	padding-bottom: 0;
	color: #7e7c7c;
	border-top: 2px solid #000002;
}

.footer-inner {
	max-width: 1000px;
	margin: 0 auto;
}

.site-footer__header {
	display: none;
}

.site-footer a {
	color: inherit;
}

.footer-inner--copyright {
	max-width: none;
	background-color: #000000;
}

.site-footer__copyright {
	max-width: 1000px;
	margin: 0 auto;
	color: #fff;

	a {
		color: #fff;
	}
}