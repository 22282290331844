.page--default .main-content {
	max-width: none;
}

.salon-locator-container {
	max-width: 1000px;
	margin: 40px auto;
	padding: 0 20px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, auto));
	justify-content: center;
	align-items: center;

	img {
		max-width: 100%;
		height: auto;
	}
}

.locator-text-container {
	margin: 20px;
}

h2.locator-header {
	font-size: 3.25em;
	font-family: $font-titles;
	font-weight: 400;
}

p.locator-text {
	font-size: 1.75em;
}

.locator-link-container {
	margin-top: 50px;
}

a.locator-link {
	background: #000;
	color: #fff;
	font-size: 1.5em;
	padding: 20px;
}

@media (max-width: 768px) {
	.locator-text-container {
		text-align: center;
	}
	.locator-img-container {
		justify-self: center;
	}
}

.faux-main-banner {
	position: relative;
	margin-bottom: 20px;

	.responsive-video {
		padding-top: 41.71875%;
	}
}

.faux-main-banner__link {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 200;
	background-color: rgba(0, 0, 0, 0);
}
