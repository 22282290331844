.header-top__search {
	margin-bottom: 10px;
	overflow: hidden;
}

.search {
	max-width: 150px;
	margin: 5px 5px 5px auto;
	background: rgba(0, 0, 0, 0.6);
	border: none;
	border-radius: 3px;
}

.search__options {
	display: none;
}

.search__text {
	border-left: none;
}

.search__field {
	color: #fff;
	background: none;
}

@media only screen and (min-width: 820px) {
	.search {
		float: right;
		margin: 7px 0 0 14px;
	}
}
