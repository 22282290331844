.login {
	padding: 0;
}

.login-section__header {
	font-size: 22px;
}

.login-section__description {
	font-size: 16px;
}

.disclaimer {
	margin: 10px 0;
	padding: 0 10px;
	display: flex;
	justify-content: center;
}

.login-form__label {
	font-family: $font-titles;
	text-transform: uppercase;
}

.disclaimer-container {
	margin: 20px auto;

	.milbon-disclaimer {
		margin-bottom: 0;
		text-align: center;
	}
}