.filters__header {
	padding: 5px 0;
	color: #000;
	background-color: transparent;
	border-radius: 0;

	a {
		color: inherit;
	}
}

.filters__link {
	padding: 5px 0;

	&:hover {
		background-color: transparent;
	}

	&--selected {
		font-weight: normal;
		text-decoration: underline;
		background-color: transparent;

		&:hover {
			font-weight: normal;
			text-decoration: underline;
		}
	}
}

.filters__section {
	margin-bottom: 30px;
}

.filters__clear {
	font-size: 0.85em;
	line-height: 1.25;
}

.filters__section--brands {
	display: none;
}

.page--categories-default, 
.page--brands-default, 
.page--lines-default {
	.header-wrap h1 {
		display: none;
	}
}

.product__name {
	font-family: $font-titles;
}


.for-price {
	display: none;

	.education-all & {
		display: inline-block;
	}
}

.price__sizes {
	display: none;
}

.product-bar {
	display: none;
}

.product__detail--sku, .product__detail--size {
	display: none;
}

// --- color component lists

.prod-options-wrap {
	.prod-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.prod-list__item {
		float: none;
		clear: none;
		width: 50%;

		@media only screen and (min-width: 480px) {
			width: calc(100% / 3);
		}

		@media only screen and (min-width: 640px) {
			width: 25%;
		}
	}
}

.product-bar__bottom {
	display: block;
	background-color: transparent;
}

.page-size, .page-sort {
	display: none;
}

.product-bar__center {
	float: none;
	max-width: 100%;
	margin: 0 auto 30px;
}

.is--logged-out {
	.price__label--sale, 
	.price__label--savings {
		display: none;
	}
}

.price__label--highlight {
	background-color: inherit;
	padding: 0px;
	color: #d32121;
	font-size: inherit;
}

.prod-info {
	font-size: 14px;
	line-height: 1.75;
}

.prod-list__description {
	font-size: 12px;
}

.also-like .prod-list__item {
	font-size: 1em;
}

.brand-img img {
	max-height: unset;
}