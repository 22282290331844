.sub-nav__header {
	margin: 20px 0 10px;
	padding: 0 5px;
	font-size: 14px;
}

@media only screen and (min-width: 820px) {
	.site-nav {
		max-width: none;
		background-color: transparent;
		border-bottom: 2px solid #000002;
	}

	.site-nav__top {
		color: #000002;
		text-transform: uppercase;

		&:hover {
			background-color: transparent;
		}

		.site-nav__item:hover & {
			text-decoration: underline;
			background-color: transparent;
		}
	}

	.sub-nav {
		margin-top: 0;
		padding-bottom: 5px;
		background-color: #fff;
		border-radius: 0;
		border-top: 2px solid #000002;
		box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
	}

	.sub-nav__inner {
		padding: 0;
		background-color: transparent;
		border-radius: 0;
	}

	.sub-nav a {
		padding: 5px;
	}

	.sub-nav__header {
		margin: 0 0 10px;
	}
}

.logged-in-only {
	display: none;
}

.is--logged-in .logged-in-only {
	display: inline-block;
}
