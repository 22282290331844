.prod-info {
	font-size: 14px;
	line-height: 1.75;
}

.prod-info__name {
	font-family: $font-titles;
	text-transform: none;
}

.prod-info__item--sku,
.prod-info__brand,
.prod-option-sku {
	display: none;
}

.prod-info__header {
	padding-bottom: 1.5em;
	border-bottom: none;
}

.price__label {
	display: none !important;
}

.price__current {
	font-family: $font-titles;
	font-weight: normal;
}

.accordion__toggle {
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
}

.also-like__header, .recently-viewed__header {
	font-family: $font-titles;
	font-weight: normal;
}

.also-like .prod-list__header, .recently-viewed .prod-list__header {
	margin-bottom: 5px;
	font-family: $font-titles;
	font-size: 16px;
}

.for-price--large {
	display: none;
}