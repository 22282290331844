.home-articles {
	max-width: 1000px;
	margin: 0 auto;
}

.home-articles__list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.home-articles__item {
	float: none;
	width: 100%;
}

.home-article__text {
	text-align: center;

	p {
		color: inherit;
		font-size: 12px;
		line-height: 1.5;
	}
}

.home-article__link {
	color: inherit;
}

.home-article__title {
	margin-bottom: 15px;
	font-family: $font-titles;
	font-size: 30px;
	font-weight: normal;
	text-transform: none;
}

.home-articles__more {
	display: none;
}

@media only screen and (min-width: 540px) {
	.home-articles__item {
		width: 50%;
	}
}

@media only screen and (min-width: 680px) {
	.home-articles__item {
		width: calc(100% / 3);
	}
}