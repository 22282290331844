.post__header {
	line-height: 2.25;
}

.post__text--list {
	text-align: center;
	border-bottom: none;

	.post__img {
		float: none;
		width: 100%;
		padding: 0;
	}

	.post__title {
		font-size: 1.5em;
		line-height: 1.25;
		text-align: center;
	}

	.post__link {
		display: none;
	}

	.post__more {
		display: none;
	}

	.post__body {
		padding-right: 5px;
		padding-left: 5px;
	}
}

.post__tertiary {
	padding-top: 30px;
	padding-bottom: 30px;
}

.fader, .cloud {
	display: none;
}

@media only screen and (min-width: 520px) {
	.post__text--list {
		float: left;
		width: calc(100% / 2);

		&:nth-child(2n+1) {
			clear: left;
		}
	}

	.fader {
		display: block;
	}
}

@media only screen and (min-width: 740px) {
	.post__text--list {
		width: calc(100% / 3);

		&:nth-child(2n+1) {
			clear: none;
		}

		&:nth-child(3n+1) {
			clear: left;
		}
	}
}

@media only screen and (max-width: 640px) {
	.post__secondary {
		clear: both;
	}

	.post__primary {
		padding: 0 10px;
	}
}

@media only screen and (min-width: 620px) {
	.post__primary {
		padding: 0 25px 0 0;
	}
}
