﻿.landing__header {
    display: none;
}

.landing-mono {
    font-family: $font-landing-mono;
    font-size: 18px;
    color: #000;
    margin: 0 auto;
    max-width: 1920px;

    .landing & h1 {
        font-size: 29px;
        font-weight: 500;
        max-width: 650px;
        margin: 0 auto;
        padding-top: 85px;
    }

    .landing & h2 {
        font-size: 40px;
        font-family: $font-landing-mono-highlight;
        font-weight: 500;
        text-align: center;
        padding-bottom: 50px;
        margin: 55px 0 0;
    }

    .landing & h3 {
        font-size: 40px;
        font-family: $font-landing-mono-highlight;
        font-weight: 500;
        margin: 0.5em 0;
    }

    .landing & ul {
        padding: 0;
    }

    li {
        list-style-type: none;
        padding: 15px 0;
    }

    a {
        color: unset;
        text-decoration: underline;
    }

    .row {
        margin: 0 auto;
        max-width: 1000px;
        padding-top: 65px;
    }

    picture {
        margin: 85px auto 0;
    }
}

.landing-highlight__container {
    background-color: #e4dfd2;
}

.landing-highlight {
    max-width: 1000px;
    margin: 0 auto;
}

.landing-highlight__item {
    padding: 0;
    background-color: #fff;

    &:first-of-type {
        color: #1c528f;
    }

    &:nth-child(2) {
        color: #912c1e;
    }

    &:last-of-type {
        color: #676e2f;

        .landing-highlight__label {
            border-right: none;
        }
    }
}

.landing-highlight__label {
    border-right: 1px solid black;
    font-size: 22px;
    line-height: 1.1;
    font-family: $font-landing-mono-highlight;
    padding: 20px;

    span {
        font-style: italic;
    }
}

.landing-highlight__header {
    display: inline-flex;
    width: 100%;
    justify-content: start;
    align-items: baseline;
    text-transform: none;
    gap: 7px;
    margin-bottom: 50px;
}

.landing-highlight__text {
    max-width: 650px;
    margin: 0 auto;
}

.landing-highlight__text--strong {
    font-weight: 700;
}

.mono-artist__quote {
    font-size: 36px;
    font-weight: 500;

    &p {
        line-height: 1.2;
    }
}

.mono-artist__name {
    font-size: 24px;
    font-weight: 500;
}

.mono-rock .row {
    padding-bottom: 65px;
}

.mono-ingredients {
    p {
        font-size: 24px;
        font-weight: 500;
    }
}

.mono-ingredient__note {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
}

.mono-ingredient__note-header {
    font-weight: 500;
}

.mono-videos {
    img {
        margin: 85px auto 0;
    }
}

@media only screen and (max-width: 819.98px) {
    .landing-mono {
        /*.landing & h1 {
            padding-top: 50px;
        }*/
        .landing & h2 {
            font-size: 36px;
        }

        .mono-artist__quote {
            font-size: 30px;
        }
        /*.landing & h3 {
            font-size: 30px;
        }*/
        /* picture {
            margin-top: 50px;
        }*/
        /*.landing & ul {
            padding: 0;
        }

        li {
            list-style-type: none;
            padding: 15px 0;
        }*/
        /*.row {
            padding-top: 50px;
        }*/
    }
}

@media only screen and (max-width: 680px) {
    .landing-mono {
        /*font-family: $font-landing-mono;
        font-size: 18px;
        color: #000;
        margin: 0 auto;
        max-width: 1920px;*/

        .landing & h1 {
            padding-top: 50px;
        }

        .landing & h2 {
            font-size: 28px;            
        }

        .landing & h3 {
            font-size: 30px;
        }

        picture {
            margin-top: 50px;
        }

        /*.landing & ul {
            padding: 0;
        }

        li {
            list-style-type: none;
            padding: 15px 0;
        }*/

        .row {            
            padding-top: 50px;
        }
    }

    .landing-highlight__container {
        padding: 0 8px;
    }

    .landing-highlight__header {
        text-align: center;
        display: block;

        img {
            margin: 5px auto;
        }
    }

    .landing-highlight__label {
        font-size: 15px;
        padding: 15px 10px;
        line-height: 1.2;
    }    

    .landing .mono-artist__quote {
        padding-top: 10px;
        font-size: 26px;
    }

    .mono-artist__name {
        font-size: 22px;
    }

    .landing .mono-rock .g-7,
    .landing .mono-ingredients p {
        padding-top: 10px;
    }

    .mono-rock .row {
        padding-bottom: 40px;
    }

    .mono-videos img {        
        margin-top: 50px;        
    }
}

@media only screen and (min-width: 680.02px) {
    .mono-artist {
        .row {
            display: flex;
            align-items: center;
            padding: 25px 0 0;
        }

        .g-8 {
            padding: 0 20px;
        }
    }

    .mono-rock {
        .row {
            display: flex;
            align-items: center;
            padding: 95px 0;
        }

        .g-7 {
            padding-left: 50px;
        }
    }

    .mono-ingredients {
        .row {
            display: flex;
            align-items: center;

            > div:last-of-type {
                padding-right: 60px;
                padding-left: 10px;
            }
        }

        .g-6 {
            padding: 0;
        }

        ul {
            padding-left: 10px;
        }
    }
}

@media only screen and (min-width: 820px) {
    .mono-rock {
        .g-7 {
            padding-left: 50px;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .mono-ingredients {
        .row {            
            > div:last-of-type {
                padding-left: 0;
            }
        }
    }
}
