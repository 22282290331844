.main-banner-wrap {
	margin-bottom: 20px;
}

.standard-banners {
	max-width: 1000px;
	margin: 0 auto;
}

.standard-banner {
	margin: 15px 0;
}

.standard-banners--secondary {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.standard-banner {
		width: 50%;
		padding: 0 10px;
	}
}

.standard-banners--tertiary {
	width: 80%;
}
