.about-summary {
	max-width: 750px;
	margin: 0 auto;
	padding: 30px 15px;
	font-family: 'Garamond', serif;
	font-size: 18px;
	line-height: 1.5;
	text-align: center;

	.about-summary__text {
		margin: 0;
		padding: 0;
	}
}

@media only screen and (min-width: 640px) {
	.about-summary {
		padding: 60px 30px;
		font-size: 28px;
	}
}

.img--full {
	display: block;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

@media only screen and (min-width: 1000px) {
	.about-full {
		margin-right: calc(-100vw / 2 + 1020px / 2);
		margin-left: calc(-100vw / 2 + 1020px / 2);
	}
}


// --- other about stuff
.page--about-us-default {
	.page-header {
		display: none;
	}

	.page-wrap {
		padding-top: 45px;
	}

	.filters__header {
		font-size: 1.25em;
	}
}