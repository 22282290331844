.locator {
	display: flex;
	flex-direction: column;
}

.locator__inner {
	position: static;
	order: 1;
	width: auto;
	margin-top: 15px;
}

.locator-results__inner {
	border: 1px solid #c8cfd1;
	background-color: #fff;
	border-radius: 3px;
}

.locator-results {
	max-height: 15em;
	overflow-y: auto;
	border: none;
	border-radius: 0;
}

.locator__map {
	order: 2;
}

@media only screen and (min-width: 520px) {
	.locator-results__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.locator-results__item {
		width: 50%;
	}
}

@media only screen and (min-width: 780px) {
	.locator__inner {
		margin-top: 0;
	}

	.locator__map {
		order: 2;
	}

	.locator-results-wrapper {
		position: absolute;
		top: 70px;
		right: 10px;
		width: 325px;
		z-index: 300;
	}

	.locator-results {
		max-height: 31em;
	}

	.locator-results__list {
		display: block;
	}

	.locator-results__item {
		width: auto;
	}
}

.locator__count {
	padding-bottom: 0;
	border: none;
}

.locator-input {
	display: inline-flex;
	margin-bottom: 15px;
	padding: 0;
	border: none;
	background-color: transparent;
}

.locator-input__values {
	align-items: center;
	border: 1px solid #c8cfd1;
	border-radius: 0;
	background-color: transparent;
}

.locator-input__location, .locator-input__range, .locator-input__submit {
	border: none;
}

.locator-input__location {
	padding-left: 15px !important;
	border-right: 1px solid #c8cfd1;
}

.locator-input__range {
	padding: 0 10px !important;
}

.locator-input__submit {
	padding: 0 0 0 5px;
}

.locator-search__submit {
	width: auto;
	height: auto;
	padding: 5px 15px;
	color: $color-highlight;
	font-size: 11px;
	background: none;
	border: 1px solid $color-highlight;
	text-transform: uppercase;
	text-indent: 0;
	white-space: normal;
	cursor: pointer;
}

@media only screen and (min-width: 380px) {
	.locator-search__submit {
		padding: 15px 30px;
		font-size: 12px;
	}
}



.locator-search__range {
	display: block;
	// font-size: 16px;
	font-size: 12px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	// border: 1px solid #aaa;
	// box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	// background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	//   linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	// background-repeat: no-repeat, repeat;
	// background-position: right .7em top 50%, 0 0;
	// background-size: .65em auto, 100%;
	background-image: url('/content/themes/milbon/images/select-arrow.png');
	background-repeat: no-repeat;
	background-position: 100% 50%;
}
.locator-search__range::-ms-expand {
	display: none;
}
.locator-search__range:hover {
	border-color: #888;
}
.locator-search__range:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.locator-search__range option {
	font-weight:normal;
}
